#charts {

  .chart-header {
    margin-left: 65px;
    margin-bottom: 30px;

    h5 {
      margin-bottom: 0;
    }
  }
}