#footer {

  &.footer-1 {
    padding: 30px 0;
    background: $body-bg;
    font-size: 15px;

    .footer__contact {

      i {
        font-size: 16px;
      }
    }

    .footer__social {

      li {
        margin: 0 10px;

        &:last-child {
          margin-right: 0;
        }

        a {

          i {
            color: lighten($text-color, 15%);
            font-size: 16px;

            &:hover {
              color: $blue-2;
            }
          }
        }
      }
    }
  }

  &.footer-2 {
    padding: 0 0 80px 0;

    @media (max-width: 767px) {
      padding-top: 30px;
    }

    .footer__menu {
      margin-bottom: 80px;

      @media (max-width: 767px) {
        margin-bottom: 50px;
      }

      li {

        @media (max-width: 565px) {
          padding: 4px 0;
        }

        a {
          color: $text-color;
        }
      }
    }

    .footer__logo {
      margin-bottom: 20px;
      max-height: 50px;
    }

    .footer__copyrights {
      margin-bottom: 0;
      font-size: 13px;
    }
  }

  &.footer-3 {

    .preamble {
      margin-bottom: 3em;
    }

    .footer__form {
      margin-bottom: 2.5em;

      .row {
        margin: 0 -8px;
      }

      .col-lg-3 {
        padding: 0 8px;
      }

      input {
        padding-left: 1rem;
        height: 49px;
        border: none;

        @media (max-width: 991px) {
          margin: 0 auto;
          width: 400px;
        }

        @media (max-width: 460px) {
          width: 100%;
        }
      }

      .btn {

        @media (max-width: 991px) {
          margin: 0 auto;
          width: 400px !important;
        }

        @media (max-width: 460px) {
          width: 100% !important;
        }
      }
    }

    p {
      color: rgba(255,255,255,.8);

      a {
        color: $white;
      }
    }

    .footer__logo {
      margin: 6rem auto 1rem auto;
      max-height: 46px;

      @media (max-width: 1199px) {
        margin-bottom: 2rem;
      }

      @media (max-width: 991px) {
        margin-top: 4.5rem;
      }

      @media (max-width: 767px) {
        margin-bottom: 3rem;
      }
    }
  }

  &.footer-4 {

    .footer__top {

      .footer__logo {
        margin: 0 auto 60px auto;
        max-height: 80px;
      }

      .preamble {

        .title {
          font-size: 2.8em;
          line-height: 1.15em;
        }

        .lead {
          margin-left: auto;
          margin-right: auto;
          width: 80%;
        }
      }
    }

    .footer__bottom {
      padding: 50px 0 40px 0;
      border-top: 1px solid rgba(255,255,255,.15);

      .footer__feature {

        p {
          color: rgba(255,255,255,.8);
        }
      }
    }
  }
}