$white: #fff;
$dark-blue: #425672;
$border-radius: 6px;
$blue: #1a65b4;
$light-blue: #e3ebf7;
$body-bg: #f2f7fb;
$header-color: #47495a;
$green: #3ECF8E;
$text-color: #6b7c93;
$blue-2: #00b4ff;
$red: #ff7677;
$gradient: linear-gradient(89deg, #4CB6FF 15%, #4DA3FF 100%);
$box-shadow: 0 4px 9px 0 rgba(23, 28, 33, .06);
