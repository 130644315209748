#testimonials {

  &.testimonials-1 {

    .blockquote {

      p {
        line-height: 36px;
      }

      footer {

        h5 {
          font-size: 1rem;
        }

        p {
          font-size: .75rem;
          text-transform: uppercase;
        }
      }
    }
  }

  &.testimonials-2 {
    position: relative;
    padding-bottom: 60px;
    overflow: hidden;

    .owl-stage-outer {
      overflow: visible;
    }

    .owl-item {
      opacity: 0;
      transition: opacity 200ms;

      &.active {
        opacity: 1;
      }
    }

    .owl-dots {
      position: absolute;
      margin-top: 80px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 99;

      @media (max-width: 767px) {
        margin-top: 60px;
      }

      .owl-dot {

        span {
          margin: 0 5px;
          width: 12px;
          height: 12px;
          background: none;
          border: 1px solid $white;
        }

        &.active {

          span {
            background: $blue-2;
            border-color: $blue-2;
          }
        }
      }
    }

    &:after {
      position: absolute;
      left: -5%;
      right: 0;
      top: -120px;
      height: 400px;
      width: 120%;
      transform: rotate(-5deg);
      background-image: linear-gradient(-180deg, $white 40%, $body-bg 100%);
      content: "";
    }

    .preamble {
      position: relative;
      z-index: 1;
    }

    .testimonials-shadow {
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      box-shadow: 0 20px 40px 0 rgba(106, 125, 148, .1);
      border-radius: $border-radius*2;
      content: "";

      &-1 {
        bottom: -13px;
        height: 30px;
        transform: scale(.94) translate3d(0, 14.31%, 3px);
        background: #f5f5f5;
        z-index: 2;

        @media (max-width: 767px) {
          transform: scale(.91) translate3d(0, 14.31%, 3px);
        }

        @media (max-width: 565px) {
          transform: scale(.85) translate3d(0, 14.31%, 3px);
        }
      }

      &-2 {
        bottom: -24px;
        height: 15px;
        transform: scale(.91) translate3d(0, 14.31%, 3px);
        background: #ebebeb;
        z-index: 1;

        @media (max-width: 767px) {
          bottom: -22px;
          transform: scale(.85) translate3d(0, 14.31%, 3px);
        }

        @media (max-width: 565px) {
          transform: scale(.78) translate3d(0, 14.31%, 3px);
        }
      }
    }

    .testimonials-card {
      position: relative;
      padding: 40px;
      background: $white;
      border-radius: $border-radius;
      box-shadow: $box-shadow;
      z-index: 3;

      @media (max-width: 767px) {
        padding: 20px 30px;
      }

      &__button {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        z-index: 99;

        i {
          font-size: 48px;
          color: darken($body-bg, 15%);
        }
      }

      .testimonials-card__prev-button {
        left: 40px;
      }

      .testimonials-card__next-button {
        right: 40px;
      }

      .blockquote {

        &__avatar {
          margin: -75px auto 30px auto;
          width: 70px;
          height: 70px;
          border-radius: 50%;
          box-shadow: $box-shadow;
          border: 3px solid $white;
        }

        > p {
          color: #888;
          font-size: 18px;
          line-height: 1.8;

          @media (max-width: 767px) {
            font-size: 16px;
          }
        }

        footer {

          &:before {
            display: none;
          }

          h5 {
            font-size: 1rem;
          }

          p {
            font-size: .75rem;
            text-transform: uppercase;
          }
        }
      }
    }
  }

  &.testimonials-5 {

    .testimonials__button {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 40%;
      transform: translateY(-50%);
      cursor: pointer;
      z-index: 99;

      svg {
        width: 28px;
        height: 28px;

        .arrow {
          fill: lighten($text-color, 15%);
        }
      }
    }

    .testimonials__prev-button {
      left: 15px;
    }

    .testimonials__next-button {
      right: 15px;
    }

    .blockquote {

      &__avatar {
        border-radius: 50%;

        @media (max-width: 767px) {
          margin-left: auto;
          margin-right: auto;
          width: 128px;
          height: 128px;
        }
      }

      p {
        line-height: 36px;
      }

      footer {

        h5 {
          font-size: 1rem;
        }

        p {
          font-size: .75rem;
          text-transform: uppercase;
        }
      }
    }
  }
}